* {
    margin: 0;
    padding: 0;
}

.hidden {
    display: none;
}

body {
    font-family: 'myriad-pro', Helvetica, sans-serif;

    color: #464e54;

    background-image: url('/assets/background.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;

    background-color: #efefef;
}
    .root {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .App {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

header {
    height: 79px;
    width: 820px;

    margin: 40px 40px 40px 40px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

    header div.logo {
        height: inherit;
        width: 127px;
    }

    header nav {
        height: inherit;
        width: 350px;

        line-height: 100px;
    }

        nav ul {
            height: inherit;

            list-style: none;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
            nav ul li {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: center;
            }

            nav ul li a {
                height: 30px;

                line-height: 30px;

                font-size: 16px;

                text-decoration: none;

                color: #464e54;

                border-bottom: 2px solid transparent;

                transition: color 0.3s, border-bottom-color 0.3s;
            }

                nav ul li a:hover, nav ul li a.active  {
                    color: #bc1717;
                }

                nav ul li a:active, nav ul li a:focus {
                    color: #000000;
                }

                nav ul li a.active {
                    color: #bc1717;
                    border-bottom: 2px solid #bc1717;
                }

section {
    width: 900px;
}

    section div.title {
        width: 550px;

        margin: 0 0 0 40px;
    }

        section div.title h1 {
            margin-bottom: 40px;

            color: #bc1717;

            font-size: 40px;
        }

    section nav.main {
        height: 30px;
        width: 820px;

        margin: 0 40px 40px 40px;

        line-height: 30px;
    }

        section nav.main ul li a {
            font-size: 18px;

            border-bottom: unset;
        }

            section nav.main ul li a.active {
                border-bottom: unset;
            }

    section ul.list {
        list-style-type: none;

        margin: 15px 0 15px 15px;

        display: flex;
        flex-direction: column;

        gap: 12px;
    }

        section ul.list li {
            display: flex;
            gap: 10px;
        }

            section ul.list li > div {
                display: flex;

                align-self: center;

                gap: 3px;
            }

    section article {
        min-height: 200px;
        width: calc(898px - 80px);

        padding: 40px;

        border-radius: 4px;

        border: 1px solid #c6c6c6;

        background-color: #ffffff;
    }

        section article div.service {
            min-height: 120px;

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

            section article div.service div.image {
                min-height: 120px;
                width: 120px;
            }

                section article div.service div.image img {
                    height: 100px;
                    width: 100px;

                    margin: 10px;
                }

            section article div.service div.content {
                width: calc(100% - 120px - 30px);

                margin-left: 30px;
            }

        section article p {
            color: #464e54;

            text-align: justify;

            font-size: 16px;

            padding: 0 0 10px 0;
        }

            section article a {
                color: #464e54;

                font-weight: bold;

                text-decoration: none;
            }

                section article a:hover, section article a:active, section article a:focus {
                    text-decoration: underline;
                }

        section article h2.title-line,
        section article h2.line-title-line {
            line-height: 20px;

            margin: 55px 0 30px 0;

            font-size: 18px;

            color: #bc1717;

            overflow: hidden;
        }

        section article h2.line-title-line {
            margin: 65px 0 50px 0;

            text-align: center;
        }

            section article h2.title-line:after,
            section article h2.line-title-line:after,
            section article h2.line-title-line:before {
                height: 100%;
                width: 100%;

                display: inline-block;

                margin: 0 -100% 5px 25px;

                content: '';

                border-bottom: 1px solid #c6c6c6;
            }

            section article h2.line-title-line:before {
                margin: 0 25px 5px -100%;
            }

        section article div.progress {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            margin: 0 0 20px 0;
        }

            section article div.progress span {
                font-size: 16px;
                font-weight: normal;

                text-align: center;
            }

            section article div.progress span.progress-arrow {
                display: block;

                margin-top: 5px;

                transform: rotate(-90deg);
                height: 16px;
            }

    section div.gallery-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 30px;
        width: 820px;

        margin: 40px 40px 8px 40px;

        line-height: 30px;
    }

        section div.gallery-details span, section div.gallery-details a {
            font-size: 16px;

            color: #464e54;

            text-decoration: none;

            transition: margin 0.3s, color 0.3s;
        }

        section div.gallery-details a:hover {
            margin-right: 3px;

            color: #bc1717;
        }

    section div.gallery-lightbox {
        margin: 0 20px 0 20px;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }

        section div.gallery-lightbox div.image {
            height: 500px;
            width: 100%;

            background-color: #464e54;

            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        section div.gallery-lightbox div.previews {
            width: 100%;
            min-height: 80px;

            margin: 15px 0 0 0;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

            section div.gallery-lightbox div.previews div.controls {
                height: inherit;
                width: 32px;

                display: flex;
                flex-direction: column;
                justify-content: center;
            }

                section div.gallery-lightbox div.previews div.left {
                    float: left;
                }

                section div.gallery-lightbox div.previews div.right {
                    float: right;
                }

            section div.gallery-lightbox div.previews div.images {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
            }

                section div.gallery-lightbox div.previews div.images img {
                    margin: 10px;
                }

    section div.form {
        width: 100%;

        margin: 20px 0 20px 0;
    }

        section div.form div.items {
            margin: 0 0 30px 0;

            display: flex;
            flex-direction: row;
        }

            section div.form div.items div.item {
                margin-left: 30px;
            }

                section div.form div.items div.item:first-child {
                    margin-left: unset;
                }

            section div.form label {
                display: block;

                margin: 0 0 3px 0;
            }

            section div.form input[type="text"],
            section div.form textarea,
            section div.form button[type="submit"] {
                background-color: #efefef;

                padding: 8px;

                border: unset;
                box-shadow: unset;
                box-sizing: unset;

                color: #464e54;

                font-family: 'myriad-pro', Helvetica, sans-serif;

                border: 1px solid #c6c6c6;

                border-radius: 2px;
            }

            section div.form input[type="text"] {
                width: 250px;
            }

            section div.form textarea {
                width: calc(100% - 18px);
                min-height: 130px;

                resize: vertical;
            }

            section div.form button[type="submit"] {
                display: block;

                margin: 25px 0 0 0;

                padding: 10px;

                cursor: pointer;

                font-weight: bold;
                text-transform: uppercase;

                transition: color 0.3s;
            }

                section div.form button[type="submit"]:hover {
                    color: #bc1717;
                }

    section div.info {
        min-height: 90px;

        margin: 50px 10px 50px 10px;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }

        section div.info div.address {
            min-height: 90px;
            width: 250px;

            line-height: 30px;
        }

            section div.info div.address a {
                text-decoration: none;
                font-weight: normal;
            }

                section div.info div.address a:hover,
                section div.info div.address a:active {
                    text-decoration: underline;
                }

        section div.info div.social {
            height: 90px;
            max-width: 450px;

            margin-left: 30px;

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }

            section div.info div.social div.item {
                height: 45px;

                flex: unset;
            }

                section div.info div.social div.item img {
                    margin-top: 9px;
                }

                section div.info div.social div.item a {
                    line-height: 45px;
                }

    section div.gallery-pagination {
        margin-top: 30px;

        display: flex;
        justify-content: center;
    }

    section div.gallery {
        min-height: 100px;
        width: 100%;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

        .SRLThumbnails {
            overflow-y: hidden !important;
        }

        section div.gallery div.album {
            min-height: 330px;
            width: 280px;

            margin-bottom: 20px;

            cursor: pointer;

            position: relative;
        }

            section div.gallery div.date {
                position: absolute;

                top: 14px;

                right: 12px;

                color: #bc1717;

                font-size: 10px;
            }

            section div.gallery div.preview {
                height: calc(280px - 20px - 2px);
                width: calc(280px - 20px - 2px);

                padding: 10px;

                border-radius: 4px;

                border: 1px solid #c6c6c6;

                background-color: #ffffff;

                transition: border 0.2s;
            }

                section div.gallery div.preview img {
                    height: 260px;
                    width: 260px;

                    object-fit: cover;

                    background-color: #464e54;
                }

                section div.gallery a {
                    text-decoration: none;

                    color: #464e54;

                    transition: color 0.3s;
                }

                    section div.gallery a:hover {
                        color: #bc1717;
                    }

                section div.gallery div.album:hover {
                    color: #bc1717;
                }

                    section div.gallery div.album:hover div.preview {
                        border: 1px solid #bc1717;
                    }

                section div.gallery h2 {
                    line-height: 25px;

                    margin-top: 15px;

                    padding-left: 8px;

                    text-align: center;

                    font-size: 16px;
                    font-weight: normal;
                }

footer {
    width: 860px;

    margin: 40px 20px 60px 20px;

    color: #bfbfbf;
}

    footer div.social {
        min-height: 26px;
        width: 100%;

        margin: 0 0 60px 0;

        display: flex;
        flex-direction: row;
        align-content: space-between;
    }

        div.social div.item {
            height: 26px;

            font-size: 16px;

            flex: auto;

            display: flex;
            flex-direction: row;
        }

            div.social div.item a {
                line-height: 26px;

                margin-left: 12px;

                color: #464e54;

                text-decoration: none;

                font-weight: normal;

                transition: color 0.3s;
            }

                div.social div.item a:hover, footer div.social div.item a:active, footer div.social div.item a:focus {
                    color: #bc1717;
                }

    footer div.details {
        width: 100%;

        font-size: 14px;

        display: flex;
        flex-direction: row;
        align-content: space-between;
    }

        footer div.details div {
            flex: auto;
        }

        footer div.details div.copy {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
        }

        footer div.details div.legal {
            text-align: right;
            margin-bottom: 12px;
        }

            footer div.details div.legal:last-child {
                margin-bottom: 0;
            }

            footer div.details div.legal span {
                margin: 0 5px 0 5px;
            }

@media (max-width: 910px) {
    header {
        width: calc(100% - 80px);
    }

    section {
        width: 100%;
    }

        section div.gallery {
            justify-content: space-around;
        }

            section div.gallery div.album {
                margin: 0 20px 20px 20px;
            }

        section div.title {
            width: calc(100% - 40px);

            margin: 0 20px 0 20px;
        }

            section div.title h1 {
                margin-bottom: 20px;
            }

        section nav.main {
            height: unset;
            width: calc(100% - 80px);

            min-height: 30px;
        }

            section nav.main ul {
                flex-wrap: wrap;
                justify-content: center;
            }

                section nav.main ul li {
                    margin: 0 20px 0 20px;
                }

        section article {
            width: calc(100% - 80px);

            border-left: none;
            border-right: none;

            border-radius: 0;
        }

            section article div.progress span {
                display: block;
                width: 50%;

                margin-bottom: 15px;
            }

                section article div.progress span:last-child {
                    margin-bottom: 0;
                }

            section article div.progress span.progress-arrow {
                display: none;

                transform: unset;
                height: auto;
            }

        section div.form div.items {
            justify-content: space-between;
        }

            section div.form div.items div.item {
                width: calc(50% - 10px);
                margin-left: unset;
            }

                section div.form input[type="text"] {
                    width: calc(100% - 18px);
                }

                    section div.form div.items div.item:first-child {
                        margin-right: 10px;
                    }

                    section div.form div.items div.item:last-child {
                        margin-left: 10px;
                    }

                section div.form button[type="submit"] {
                    width: calc(100% - 22px);
                }

    footer {
        width: calc(100% - 40px);

        margin: 20px 20px 40px 20px;
    }

        footer div.social {
            flex-direction: column;
            align-content: center;

            margin: 0 0 20px 0;
        }

        footer div.social div.item {
            margin: 8px 0 8px 0;

            justify-content: center;
        }

        footer div.details {
            flex-direction: column;
        }

            footer div.details div.copy,
            footer div.details div.legal {
                margin: 12px 0 12px 0;

                text-align: center;
            }
}


@media (max-width: 830px) {
    section div.info {
        margin: 25px 10px 25px 10px;
    }

        section div.info div.address {
            margin-bottom: 15px;
        }

        section div.info div.social {
            margin-left: 0;
        }
}


@media (max-width: 600px) {
    section div.info div.social {
        min-height: 90px;
        height: unset;
    }

    section div.info div.social div.item {
        width: 100%;
    }
}


@media (max-width: 500px) {
    header {
        height: unset;

        min-height: 79px;

        flex-direction: column;
        justify-content: center;
    }

        header div.logo {
            width: 100%;

            margin-bottom: 15px;

            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        header nav {
            width: 100%;

            display: flex;
            flex-direction: row;
            justify-content: center;
        }

            header nav ul {
                justify-content: space-around;
            }

                header nav ul li {
                    margin: 0 15px 0 15px;
                }

    section article div.progress span {
        width: 100%;

        margin-bottom: 15px;
    }

        section article div.progress span.progress-arrow {
            display: block;

            transform: unset;
            height: auto;
        }

    section div.form div.items {
        flex-wrap: wrap;

        margin-bottom: 10px;
    }

        section div.form div.items div.item {
            width: 100%;

            margin: 5px 0 5px 0;
        }

            section div.form div.items div.item:first-child {
                margin-right: unset;
            }

            section div.form div.items div.item:last-child {
                margin-left: unset;
            }

    section article div.service {
        justify-content: center;
    }

        section article div.service div.content {
            width: 100%;

            margin-left: unset;
        }

    footer {
        margin-bottom: 25px;
    }

        footer div.social {
            margin-bottom: 25px;
        }

        footer div.details div.legal span {
            display: block;

            margin: 4px 0 4px 0;
        }
}


@media (max-width: 400px) {
    header nav ul {
        text-align: center;

        display: flex;
        flex-direction: column;
    }

    nav ul li a.active {
        border-bottom: 2px solid transparent;
    }
}